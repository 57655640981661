<template>
  <div class="me_outpatient_container">
    <div class="header">
      <div class="picker">
        <van-field
          readonly
          clickable
          label="选择就诊人"
          :value="patName"
          @click="patNamePickerShow = true"
          right-icon="arrow-down"
        />
        <van-popup v-model="patNamePickerShow" round position="bottom">
          <van-picker
            show-toolbar
            :columns="patNameList"
            @cancel="patNamePickerShow = false"
            @confirm="patNameConfirm"
          />
        </van-popup>
      </div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o"
        >温馨提示：默认查询三个月内的订单记录</van-notice-bar
      >
      <div class="picker">
        <van-field
          readonly
          clickable
          label="选择订单日期"
          :value="date"
          @click="datePickerShow = true"
          right-icon="arrow-down"
          class="picker"
        />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="dateConfirm"
            @cancel="dateCancel"
          />
        </van-popup>
      </div>
    </div>
    <div class="list">
      <div
        v-for="(item, index) in recordList"
        :key="index"
        class="item"
        @click="navDetail(item)"
      >
        <div class="top">
          <van-image
            class="avatar"
            width="60"
            height="60"
            src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png"
            fit="contain"
          />
          <div class="info">
            <div>处方号：{{ item.hospitalOrderNum }}</div>
            <div>就诊人：{{ item.patientName }}</div>
            <div>缴费时间：{{ timestampToTime(item.createTime) }}</div>
          </div>
          /<van-tag type="danger" class="danger" v-if="item.isPay == '7'"
            >缴费异常</van-tag
          >
          <van-icon name="arrow" class="right" size="20px" v-else />
        </div>
        <div class="bottom">门诊费：{{ item.cost }}元</div>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="recordList === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Outpatient",
  data() {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      patCardNo: "",
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      recordList: [],
      createTime: ""
    };
  },
  created() {
    this.patCardNo = this.$route.query.patCardNo;
    this.date = this.formatTime(-90);
    this.end = this.formatTime(0);
    this.getMembers();
  },
  methods: {
    timestampToTime(timestamp) {
      let date = new Date(timestamp);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      return time;
    },
    //获取所有建档人
    async getMembers() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(
            res.data[i].name + "(" + res.data[i].pat_card_no + ")"
          );
        }
        if (this.patCardNo === undefined) {
          this.patNameIndex = 0;
        } else {
          this.patNameIndex = this.patNameArray.findIndex(item => {
            return item.pat_card_no === this.patCardNo;
          });
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getClinicPaidRecords();
      } else {
        this.$toast.fail("暂无建档，请先建档");
        // setTimeout(() => {
        //   this.$router.push({
        //     path: "/file"
        //   });
        // }, 2000);
      }
    },
    //获取门诊已缴费明细
    async getClinicPaidRecords() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/getClinicPaidRecords",
        {
          openId: window.localStorage.getItem("openId"),
          beginDate: this.date,
          endDate: this.end,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          idCard: this.patNameArray[this.patNameIndex].card_no
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.recordList = res.data;
      } else {
        this.recordList = "";
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm(value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getClinicPaidRecords();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime(num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat(dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm(value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getClinicPaidRecords();
    },
    dateCancel() {
      this.datePickerShow = false;
    },
    navDetail(item) {
      if (item.isPay == "7" && item.payType) {
        window.localStorage.setItem("refund", "0");
        window.localStorage.setItem("item", JSON.stringify(item));
        this.$router.push({
          path: "/order/outpatient/refund"
        });
      } else if (item.isPay == "7" && item.costName == "门诊缴费") {
        this.$toast.fail("公众号暂只支持医保退款");
      } else {
        this.$router.push({
          path: "/order/outpatient/detail",
          query: {
            patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
            patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
            idCard: this.patNameArray[this.patNameIndex].card_no,
            agtOrdNum: item.agtOrdNum,
            orderNum: item.hospitalOrderNum
          }
        });
      }
    }
  }
};
</script>

<style scope>
.me_outpatient_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.me_outpatient_container .header .picker .van-cell {
  padding: 30px;
}

.me_outpatient_container .header .picker .van-cell::after {
  border: 0;
}

.me_outpatient_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.me_outpatient_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.me_outpatient_container .header .picker .van-icon {
  font-size: 40px;
}

.me_outpatient_container .header .picker .van-picker-column {
  font-size: 40px;
}

.me_outpatient_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.me_outpatient_container .header .picker .van-picker__cancel,
.me_outpatient_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.me_outpatient_container .header .van-notice-bar__content {
  font-size: 32px;
}

.me_outpatient_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.me_outpatient_container .list {
  padding-bottom: 60px;
}

.me_outpatient_container .list .item {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
}

.me_outpatient_container .list .item .top {
  position: relative;
  display: flex;
  font-size: 34px;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 2px dotted rgb(201, 191, 191);
}

.me_outpatient_container .list .item .top .avatar {
  flex: 1;
}

.me_outpatient_container .list .item .top .info {
  flex: 4;
  margin-left: 20px;
  line-height: 70px;
}

.me_outpatient_container .list .item .bottom {
  font-size: 40px;
  padding: 20px 30px;
}

.me_outpatient_container .list .item .top .van-icon {
  position: absolute;
  top: 40%;
  right: 30px;
}
.me_outpatient_container .list .item .top .danger {
  position: absolute;
  top: 40%;
  right: 30px;
}
</style>
